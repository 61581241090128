import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {take} from 'rxjs/operators';
import {environment} from "../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class BaseService {
  constructor(private http: HttpClient) {
  }

  // eslint-disable-next-line @typescript-eslint/member-ordering
  httpOptions = {
    headers: new HttpHeaders({
      // eslint-disable-next-line @typescript-eslint/naming-convention
      'Content-Type': 'text/html; charset=UTF-8'
    })
  };

  baseUrl = environment.apiUrl;

  getAll(endPoint: any) {
    return this.http.get(`${this.baseUrl}/${endPoint}`).pipe(take(1));
  }

  getDist(endPoint: any) {
    return this.http.get(`${endPoint}`).pipe(take(1));
  }

  getAllWithId(endPoint: any, id: any) {
    return this.http.get(`${this.baseUrl}/${endPoint}/${id}`).pipe(take(1));
  }

  getById(endPoint: any, id: any) {
    return this.http.get(`${this.baseUrl}/${endPoint}/${id}`).pipe(take(1));
  }

  delete(endPoint: any, id: any) {
    return this.http.delete(`${this.baseUrl}/${endPoint}/${id}`).pipe(take(1));
  }

  save(endPoint: any, object: any) {
    return this.http.post(`${this.baseUrl}/${endPoint}`, object).pipe(take(1));
  }

  save2(endPoint: any, object: any) {
    // eslint-disable-next-line @typescript-eslint/ban-types
    return this.http.post<String>(`${this.baseUrl}/${endPoint}`, object, this.httpOptions).pipe(take(1));
  }

  getResponseText(endPoint: any) {
    return this.http.get(`${this.baseUrl}/${endPoint}`, this.httpOptions).pipe(take(1));
  }

  update(endPoint: any, id, object: any) {
    return this.http.put(`${this.baseUrl}/${endPoint}/${id}`, object).pipe(take(1));
  }

  updateWithNoParams(endPoint: any, object: any) {
    return this.http.put(`${this.baseUrl}/${endPoint}`, object).pipe(take(1));
  }

  update2EndPoint(endPoint1: any, endPoint2: any, id, object: any) {
    return this.http.put(`${this.baseUrl}/${endPoint1}/${id}/${endPoint2}`, object).pipe(take(1));
  }

  updateWithoutId(endPoint: any, object: any) {
    return this.http.put(`${this.baseUrl}/${endPoint}`, object).pipe(take(1));
  }

  saveWithProgress(endPoint: any, object: any) {
    return this.http.post(`${this.baseUrl}/${endPoint}`, object, {observe: 'events', reportProgress: true});
  }


  upload(files: Set<File>, url: string) {
    const formData = new FormData();

    files.forEach(file => formData.append('file', file, file.name));
    console.log(formData);
    console.log(files);

    return this.http.post(url, formData, {observe: 'events', reportProgress: true});
  }

}
